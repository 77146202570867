import {
  FileText,
  File,
  Folder,
  FilePlus2,
  Code,
  FileSpreadsheet,
  FolderCode,
  Braces,
  AlertCircle,
} from "lucide-react";
import { PrimaryAssetRecord, NodeStatus } from "@/api/types/node";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
} from "@/components/ui/tooltip";
import { SourceAssetRecord } from "../UserDefinedScope/AssetList";

interface CellContentWithStatusIconProps {
  content: PrimaryAssetRecord | SourceAssetRecord;
}

export const getDocumentIcon = (type: string, iconSize: string = "h-4 w-4") => {
  const iconMap: { [key: string]: React.ReactNode } = {
    driverTemplate: <FilePlus2 className={`${iconSize}`} />,
    template: <FilePlus2 className={`${iconSize}`} />,
    document: <FileText className={`${iconSize} text-blue-500`} />,
    application_note: (
      <File className={`${iconSize} text-blue-500 dark:text-blue-300`} />
    ),
    PAGE: <File className={`${iconSize} text-blue-500 dark:text-blue-300`} />,
    codebase: (
      <Code className={`${iconSize} text-orange-500 dark:text-orange-300`} />
    ),
    CODEBASE: (
      <Code className={`${iconSize} text-orange-500 dark:text-orange-300`} />
    ),
    "codebase-directory": (
      <FolderCode
        className={`${iconSize} text-orange-500 dark:text-orange-300`}
      />
    ),
    collection: (
      <Folder className={`${iconSize} text-yellow-500 dark:text-yellow-300`} />
    ),
    pdf_summary: (
      <FileSpreadsheet
        className={`${iconSize} text-rose-500 dark:text-rose-300`}
      />
    ),
    "supplemental-document": (
      <FileSpreadsheet
        className={`${iconSize} text-rose-500 dark:text-rose-300`}
      />
    ),
    FILE: (
      <FileSpreadsheet
        className={`${iconSize} text-rose-500 dark:text-rose-300`}
      />
    ),
    "codebase-file": (
      <Braces className={`${iconSize} text-amber-500 dark:text-amber-300`} />
    ),
  };
  return iconMap[type] || <File className={iconSize} />;
};

export const ContentIconWithStatus: React.FC<
  CellContentWithStatusIconProps
> = ({ content }) => {
  const status = content?.versions?.[0]?.status;
  const iconSize = "h-4 w-4";

  const IconComponent = getDocumentIcon(content.kind, iconSize);

  return (
    <div className="flex justify-center flex-shrink-0 text-muted-foreground p-2 pl-0 relative">
      <TooltipProvider>
        {(status === NodeStatus.GENERATION_COMPLETE ||
          status === NodeStatus.USER_DATA) &&
          IconComponent}
        {status === NodeStatus.GENERATING && (
          <Tooltip>
            <TooltipTrigger asChild>
              <div>{IconComponent}</div>
            </TooltipTrigger>
            <TooltipContent>Generating documentation</TooltipContent>
          </Tooltip>
        )}
        {(!status || status === NodeStatus.GENERATION_ERROR) && (
          <Tooltip>
            <TooltipTrigger asChild>
              <div>
                <AlertCircle className={`${iconSize} text-red-500`} />
              </div>
            </TooltipTrigger>
            <TooltipContent>Error generating documentation</TooltipContent>
          </Tooltip>
        )}
      </TooltipProvider>
    </div>
  );
};
